import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      style: _normalizeStyle(`background-color: ${_ctx.color || _ctx.companyColor}}`)
    }, ".", 4),
    _createElementVNode("span", {
      style: _normalizeStyle(`background-color: ${_ctx.color || _ctx.companyColor}}`)
    }, ".", 4),
    _createElementVNode("span", {
      style: _normalizeStyle(`background-color: ${_ctx.color || _ctx.companyColor}}`)
    }, ".", 4)
  ]))
}